<script setup>
import Utility, { handleApiResponse } from '~/server/utils/utils';

const runtimeConfig = useRuntimeConfig();

// Initialize route and i18n
const route = useRoute();
const { t, locale } = useI18n();
let lang = locale.value.split('-')[0];

useHead({
  meta: [{ name: 'robots', content: 'noindex, nofollow' }],
});

const rule = useRobotsRule();
rule.value = 'noindex, nofollow';

// Initialize head using useLocaleHead
let head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
});

// Compute the page title
const title = computed(() => route.meta.title ?? t('TourScanner - Find your ideal travel activity at the best price'));

// Default Open Graph Image
const defaultOgImage = 'https://tourscanner.com/blog/wp-content/uploads/og_image.png';

useSeoMeta({
  ogUrl: computed(() => `${runtimeConfig.public.siteURL}${route.path}`),
});

// Function to update href links
const updateHrefsLinks = async (currentPath) => {
  const urlScomposed = Utility.scomposeURL(currentPath);
  const isStrapiPage = route?.name?.includes('slug');

  // Check if any of the 4 parameters are present
  if (
    urlScomposed.result.s ||
    urlScomposed.result.c ||
    urlScomposed.result.i ||
    urlScomposed.result.t ||
    isStrapiPage
  ) {
    head = useLocaleHead({
      addDirAttribute: true,
      identifierAttribute: 'id',
      addSeoAttributes: false,
    });
    try {
      // If the path contains "artcles_test" then take the language from the part after "blog"
      if (route.path.split('/').includes('blog')) {
        if (['de', 'fr', 'it', 'nl', 'pt', 'es'].includes(lang)) {
          lang = route.path.split('/')[2];
          locale.value = lang;
        }
      }

      // Call the API to get href links
      const { data } = await handleApiResponse(`/api/getHrefsLinks`, {
        params: {
          path: currentPath,
          strapi: isStrapiPage,
          lang: lang.value,
        },
      });

      // Map the API response to match the head links structure
      const apiLinks =
        data.value?.map((link) => ({
          ...link,
          id: link.id, // Ensure the ID matches the one set by useLocaleHead
        })) || [];

      // Get the canonical path
      let canonicalPath;
      canonicalPath = `${runtimeConfig.public.siteURL}${route.path}`;
      // Update the canonical link
      apiLinks.push({
        rel: 'canonical',
        href: canonicalPath,
        id: 'canonical',
      });

      // Update the head links
      head.value.link = apiLinks;
    } catch (error) {
      console.error('Error fetching hrefs links:', error);
      // Optionally, you can reset the links or handle the error as needed
      head.value.link = [];
    }
  }
};

// Initial API call on component mount
updateHrefsLinks(route.path);

// Watch for route changes and update href links accordingly
watch(
  () => route.path,
  (newPath) => {
    updateHrefsLinks(newPath);
  },
  { immediate: false }, // Set to false since we've already called it initially
);
</script>

<template>
  <div class="">
    <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
      <Head>
        <Title>{{ title }}</Title>

        <!-- Dynamically render link elements -->
        <template v-for="link in head.link" :key="link.id">
          <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
        </template>

        <!-- Dynamically render meta elements -->
        <template v-for="meta in head.meta" :key="meta.id">
          <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
        </template>

        <!-- Static meta elements -->
        <Meta name="theme-color" content="#33B1E3" />
        <Meta property="og:image" :content="route.meta.ogImage || defaultOgImage" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <!-- iOS meta tags & icons -->
        <meta name="apple-itunes-app" content="app-id=1496550592, app-argument=https://tourscanner.com" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="apple-mobile-web-app-title" content="TourScanner" />
        <link rel="apple-touch-icon" href="/website/public/apple-touch-icon.png" />
      </Head>
      <Body>
        <slot />
      </Body>
    </Html>
  </div>
</template>
